import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { 
  AppHeader,
  Home,
  About,
  Shop, 
  Gallery,
  Calendar,
  Education,
  Contact,
  Footer 
} from "./sections";
import './styles/index.css';

function App() {
  return (
    <BrowserRouter>
      <AppHeader />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/education" element={<Education />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
