import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import shop1 from "../../../../assets/img/shop/shop1.png";
import shop2 from "../../../../assets/img/shop/shop2.jpg";
import shop3 from "../../../../assets/img/shop/shop3.JPG";
import shop4 from "../../../../assets/img/shop/shop4.jpg";
import shop5 from "../../../../assets/img/shop/shop5.jpg";
import shop6 from "../../../../assets/img/shop/shop6.jpg";

export const Redbubble = () => {
    return (
           <Row className="text-center">
                <Col sm md={6} lg={4} className="d-flex justify-content-center">
                    <Card style={{ width: '18rem' }} className="background-color_transparent btn-border-none shop-card_margin">
                        <Card.Img variant="top" src={shop1} style={{ height: "18rem", objectFit: 'contain'  }} />
                        <Card.Body>
                            <Card.Title className="rajdhani-semibold">Nox by Dax!</Card.Title>
                            <Card.Text className="rajdhani">
                            Prints, Stickers, and Magnets Available!
                            </Card.Text>
                            <Button className="bg-custom__dark btn btn-dark btn-border-none" variant="primary" href="https://www.redbubble.com/shop/ap/129640823" target="_blank">Order Here</Button>
                        </Card.Body>
                    </Card>
                </Col>

                <Col className="d-flex justify-content-center">
                    <Card style={{ width: '18rem' }} className="background-color_transparent btn-border-none shop-card_margin">
                        <Card.Img variant="top" src={shop2} style={{ height: "18rem", objectFit: 'contain'  }} />
                        <Card.Body>
                            <Card.Title className="rajdhani-semibold">Garnet</Card.Title>
                            <Card.Text className="rajdhani">
                                Prints and Framed Pieces Available!
                            </Card.Text>
                            <Button className="bg-custom__dark btn btn-dark btn-border-none" variant="primary" href="https://www.redbubble.com/shop/ap/129524647" target="_blank">Order Here</Button>
                        </Card.Body>
                    </Card>
                </Col>

                <Col className="d-flex justify-content-center">
                    <Card style={{ width: '18rem' }} className="background-color_transparent btn-border-none shop-card_margin">
                        <Card.Img variant="top" src={shop3} style={{ height: "18rem", objectFit: 'contain'  }} />
                        <Card.Body>
                            <Card.Title className="rajdhani-semibold">Garnet: The Experience</Card.Title>
                            <Card.Text className="rajdhani">
                                Prints and Framed Pieces Available!
                            </Card.Text>
                            <Button className="bg-custom__dark btn btn-dark btn-border-none" variant="primary"href="https://www.redbubble.com/shop/ap/129640247" target="_blank">Order Here</Button>
                        </Card.Body>
                    </Card>
                </Col>

                <Col className="d-flex justify-content-center">
                    <Card style={{ width: '18rem' }} className="background-color_transparent btn-border-none shop-card_margin">
                        <Card.Img variant="top" src={shop4} style={{ height: "18rem", objectFit: 'contain'  }} />
                        <Card.Body>
                            <Card.Title className="rajdhani-semibold">Glamour Garnet</Card.Title>
                            <Card.Text className="rajdhani">
                                Prints and Framed Pieces Available!
                            </Card.Text>
                            <Button className="bg-custom__dark btn btn-dark btn-border-none" variant="primary" href="https://www.redbubble.com/shop/ap/129639980" target="_blank">Order Here</Button>
                        </Card.Body>
                    </Card>
                </Col>

                <Col className="d-flex justify-content-center">
                    <Card style={{ width: '18rem' }} className="background-color_transparent btn-border-none shop-card_margin">
                        <Card.Img variant="top" src={shop5} style={{ height: "18rem", objectFit: 'contain'  }} />
                        <Card.Body>
                            <Card.Title className="rajdhani-semibold">Garnet in Neon</Card.Title>
                            <Card.Text className="rajdhani">
                                Prints and Framed Pieces Available!   
                            </Card.Text>
                            <Button className="bg-custom__dark btn btn-dark btn-border-none" variant="primary" href="https://www.redbubble.com/shop/ap/129523633" target="_blank">Order Here</Button>
                        </Card.Body>
                    </Card>
                </Col>

                <Col className="d-flex justify-content-center">
                    <Card style={{ width: '18rem' }} className="background-color_transparent btn-border-none shop-card_margin">
                        <Card.Img variant="top" src={shop6} style={{ height: "18rem", objectFit: 'contain'  }} />
                        <Card.Body>
                            <Card.Title className="rajdhani-semibold">The Other Side of Garnet</Card.Title>
                            <Card.Text className="rajdhani">
                                Prints and Framed Pieces Available! 
                            </Card.Text>
                            <Button className="bg-custom__dark btn btn-dark btn-border-none" variant="primary" href="https://www.redbubble.com/shop/ap/129639552" target="_blank">Order Here</Button>
                        </Card.Body>
                    </Card>
                </Col>

           </Row>
           
        
    )
}