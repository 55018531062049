import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from "@cloudinary/url-gen";
import { fit } from "@cloudinary/url-gen/actions/resize";

export const PhotoCarousel = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const cld = new Cloudinary({
    cloud: {
      cloudName: 'ddoi2ghcy'
    }
  });

  // const image1 = cld.image('noxfalls/gallery/NoxFalls_BHOF_56_copy_olqub1'); 
  // image1.resize(fit().width(365));

  const image2 = cld.image('noxfalls/gallery/IMG_0275_rwtmwu'); 
  image2.resize(fit().height(500));

  const image3 = cld.image('noxfalls/gallery/NoxFalls_BHOF_49_copy_pyjand'); 
  image3.resize(fit().height(500));

  const image4 = cld.image('noxfalls/gallery/final2_auohev'); 
  image4.resize(fit().height(500));

  const image5 = cld.image('noxfalls/gallery/2nd_neon_look_zqnwnz'); 
  image5.resize(fit().height(500));

  const image6 = cld.image('noxfalls/gallery/1copy_kzqvx6'); 
  image6.resize(fit().height(500));

  const image7 = cld.image('noxfalls/gallery/2copy_jy48m6'); 
  image7.resize(fit().height(500));

  const image8 = cld.image('noxfalls/gallery/20221002.NoxColors_0100_pvohkl'); 
  image8.resize(fit().height(500));

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} className="justify-content-center">

      {/* <Carousel.Item className='text-center'>
        <AdvancedImage cldImg={image1} />
        <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Gina Barbara Photography</p>
        </Carousel.Caption>
      </Carousel.Item> */}

      <Carousel.Item className='text-center'>
        <AdvancedImage cldImg={image2} />
        <Carousel.Caption>
          {/* <h3>Second slide label</h3> */}
          <p>Israel Collum</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item className='text-center'>
        <AdvancedImage cldImg={image3} />
        <Carousel.Caption>
          {/* <h3>Third slide label</h3> */}
          <p>
            Gina Barbara Photography
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      
      <Carousel.Item className='text-center'>
        <AdvancedImage cldImg={image4} />
        <Carousel.Caption>
          {/* <h3>Third slide label</h3> */}
          <p>
          Israel Collum
          </p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item className='text-center'>
        <AdvancedImage cldImg={image5} />
        <Carousel.Caption>
          {/* <h3>Third slide label</h3> */}
          <p>
          Israel Collum
          </p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item className='text-center'>
        <AdvancedImage cldImg={image6} />
        <Carousel.Caption>
          {/* <h3>Third slide label</h3> */}
          <p>
            Shannon Swift
          </p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item className='text-center'>
        <AdvancedImage cldImg={image7} />
        <Carousel.Caption>
          {/* <h3>Third slide label</h3> */}
          <p>
            Shannon Swift
          </p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item className='text-center'>
        <AdvancedImage cldImg={image8} />
        <Carousel.Caption>
          {/* <h3>Third slide label</h3> */}
          <p>
            Jules Doyle
          </p>
        </Carousel.Caption>
      </Carousel.Item>

    </Carousel>
  );
}
