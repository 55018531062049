import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";


export const HeadlinerSection = () => {
    return (
        <Row>
            <Col>
                <h3 className="subheader__text rajdhani-semibold">Festival Headliner & Featured Appearances</h3>
                <h4 className="rajdhani-semibold">2022</h4>
                <ListGroup variant="flush">
                <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                        Burlesque Beyond
                        <br></br>
                        Philadelphia, PA
                    </ListGroup.Item>
                    <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                        Tulsa Burlesque Festival
                        <br></br>
                        Tulsa, OK
                    </ListGroup.Item>
                    <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                        The Burlesque Hall of Fame Tournament of Tease Competitor for Queen of Burlesque
                        <br></br>
                        Las Vegas, NV
                    </ListGroup.Item>
                    <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                        The Hostess City Hoedown
                        <br></br>
                        Savannah, GA
                    </ListGroup.Item>
                    <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                        Panama Burlesque Festival
                        <br></br>
                        Casco Antiguo, Panama City
                    </ListGroup.Item>
                    <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                        Pacific Northwest Burlesque Festival
                        <br></br>
                        Olympia, WA
                    </ListGroup.Item>
                    <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                        Moisture Festival
                        <br></br>
                        Seattle, WA
                    </ListGroup.Item>
                </ListGroup>
                <h4 className="rajdhani-semibold">2021</h4>
                <ListGroup variant="flush">
                    <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                        Savannah Burlesque Festival
                        <br></br>
                        Savannah, GA
                    </ListGroup.Item>
                    <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                        Seattle Boylesque Festival
                        <br></br>
                        Seattle, WA
                    </ListGroup.Item>
                </ListGroup>
                <h4 className="rajdhani-semibold">2019</h4>
                <ListGroup variant="flush">
                    <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                        The Mobile Burlesque Festival
                        <br></br>
                        Mobile, AL
                    </ListGroup.Item>
                    <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                        Viva Las Vegas, Competitor
                        <br></br>
                        Las Vegas, NV
                    </ListGroup.Item>
                    <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                        The Northwest Vaudville Festival
                        <br></br>
                        Portland, OR
                    </ListGroup.Item>
                    <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                        What the Funk Festival
                        <br></br>
                        Seattle, WA
                    </ListGroup.Item>
                    <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                        Oregon Burlesque Festival, Headliner & Judge
                        <br></br>
                        Portland, OR
                    </ListGroup.Item>
                    <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                        The Bristol Burlesque Festival
                        <br></br>
                        Bristol, UK
                    </ListGroup.Item>
                </ListGroup>
                <h4 className="rajdhani-semibold">2018</h4>
                <ListGroup variant="flush">
                    <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                        The Ohio Burlesque Festival
                        <br></br>
                        Cleveland, OH
                    </ListGroup.Item>
                    <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                        Oregon Burlesque Festival, Competitor & Queen - Won
                        <br></br>
                        Protland, OR
                    </ListGroup.Item>
                    <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                        The Burlesque Hall of Fame Tournament of Tease, Competitor for Best Debut
                        <br></br>
                        Las Vegas, NV
                    </ListGroup.Item>
                </ListGroup>
                <br></br>
            </Col>
        </Row>
    )
}