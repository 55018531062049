import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { PhotoCarousel } from "./components/PhotoCarousel";

export const Gallery = () => {
    return (
        <div className="bg-gradient_gallery">
            <br></br>
            <Container>
            <h1 class="home-subheader__text pacifica">Gallery</h1>
            <br></br>
            <Row>
                <Col md lg={{ span: 8, offset: 2 }}>
                    <PhotoCarousel />
                </Col>
            </Row>
        </Container>
        <br></br>
        </div>
        
        
        
    )
}