import React from "react";
import { Link } from "react-router-dom";
import {
    Container, 
    Row, 
    Col, 
    Image, 
    ListGroup
} from "react-bootstrap";
import edu1 from "../../assets/img/education/education-gutter.jpg";
import edu2 from "../../assets/img/education/education-character.jpg";


export const Education = () => {
    return (
        <div className="bg-gradient_gallery">
            <br></br>
            <Container>
                <h2 className="pacifica subheader__text">Available Classes & Sessions</h2>
                <Row>
                    <Col sm lg={4} className="d-flex justify-content-center">
                        <Image 
                        className="img-height_50vh"
                            src={edu1}
                            fluid={true}
                            roundedCircle={true}
                            thumbnail={true}
                        ></Image>
                    </Col>
                    <Col sm lg={8}>
                        <h4 className="rajdhani-semibold">Gutter Glamour</h4>
                        <ListGroup variant="flush">
                            <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                                A soft-handed course in introducing direct and indirect filth within your routines in conjunction with one’s tease and skill,  growing comfortable with its natural flow. 
                            </ListGroup.Item>
                            <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                                Gutter Glamour is an “stagefilth” -driven experience that helps the performer explore their body, awareness and the weilding of blatant sexual gestures/displays. Using inclusive movements , stillness and overall power poses to enhance your performances and encourage the confidence needed for growth.
                            </ListGroup.Item>
                            <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                                Please wear clothing that will enable movement , knee pads (optional but not required) and shoes of your choice if desired. 
                            </ListGroup.Item>
                            <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                                Notepad optional as the first part of the class will include discussions to help guide the journey.
                            </ListGroup.Item>
                            <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                                **Available as a private session or group class!**
                            </ListGroup.Item>
                            <Link to="/contact">
                                <ListGroup.Item 
                                    className="background-color_transparent rajdhani btn-border-none underline-link" 
                                    action
                                    href="/contact">
                                        Schedule "Gutter Glamour" Today!
                                </ListGroup.Item>
                            </Link>
                            
                        </ListGroup>
                    </Col>
                </Row>
                
                <br></br>
                <br></br>

                <Row>
                    <Col sm lg={4} className="d-flex justify-content-center">
                        <Image 
                        className="img-height_50vh"
                            src={edu2}
                            fluid={true}
                            roundedCircle={true}
                            thumbnail={true}
                        ></Image>
                    </Col>
                    <Col sm lg={8}>
                        <h4 className="rajdhani-semibold">In Character</h4>
                        <ListGroup variant="flush">
                            <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                                A beginner-friendly guidance course in designing, planning and executing Nerdlesque that will heavily focus on costuming translations vs. cosplay options and musical choices as ways to increase the routine’s longevity in the industry without gatekeeping.  
                            </ListGroup.Item>
                            <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                                Students should come with knowledge of burlesque, preferred characters chosen as their focus, stationary/note taking devices and clothing that is comfortable to move in/measure etc.
                            </ListGroup.Item>
                            <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                                **Available as a private session or group class!**
                            </ListGroup.Item>
                            <Link to="/contact">
                                <ListGroup.Item 
                                className="background-color_transparent rajdhani btn-border-none underline-link" 
                                action
                                href="/contact">
                                    Schedule "In Character" Today!
                            </ListGroup.Item>
                            </Link>
                            
                        </ListGroup>
                    </Col>
                </Row>
                <br></br>
            </Container>
        </div>
    )
}