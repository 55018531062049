import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image'
import { AboutContent, HeadlinerSection } from "./components";
import aboutImage1 from "../../assets/img/about/background-about.jpg";
import aboutImage2 from "../../assets/img/about/nox-portrait.jpeg";

export const About = () => {
    return (
        <div className="bg-gradient_gallery">
            <br></br>
            <Container fluid="md">
                <Row>
                    <h2 className="subheader__text pacifica">About Nox Falls</h2>
                    <br></br>
                    {/* Only visible on medium */}
                    <Col className="d-none d-md-block d-lg-none">
                        <Row>
                            <Col>
                                <h5 className="rajdhani-semibold">"They'll do anything for you... in the dark."</h5>
                                <AboutContent />
                            </Col>
                            <Col>
                                <Image
                                    src={aboutImage1} 
                                    fluid={true}
                                    rounded={true}
                                    alt="Nox Falls walking toward the camera point of view wearing a sparkling black costume"
                                >
                                </Image>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <HeadlinerSection />
                            </Col>
                        </Row>
                    </Col>
                    {/* Not visible on medium */}
                    <Col className="d-block d-md-none d-lg-block" >
                        <h5 className="rajdhani-semibold">"They'll do anything for you... in the dark."</h5>
                        {/* Only visible on small or below */}
                        <Image
                            src={aboutImage1} 
                            fluid={true}
                            rounded={true}
                            className="d-block d-md-none"
                            alt="Nox Falls walking toward the camera point of view wearing a sparkling black costume"
                        >
                        </Image>
                        <AboutContent />
                        <HeadlinerSection />
                    </Col>
                    {/* Only visible on large and above */}
                    <Col className="d-none d-lg-block">
                        <Image
                            src={aboutImage1} 
                            fluid={true}
                            rounded={true}
                            alt="Nox Falls walking toward the camera point of view wearing a sparkling black costume"
                        >
                        </Image>
                        <br></br>
                        <br></br>
                        <Image
                            src={aboutImage2} 
                            fluid={true}
                            rounded={true}
                            alt="Nox Falls walking toward the camera point of view wearing a sparkling black costume"
                        >
                        </Image>
                    </Col>
                </Row>
            </Container>
            <br></br>
        </div>
    )
}