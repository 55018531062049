import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Parallax } from 'react-parallax';
import bgImg1 from '../../assets/img/home/background1.jpg';
import bgImg2 from '../../assets/img/home/background2.jpeg';
import bgImg3 from '../../assets/img/home/background3.jpg';


const insideStyles = {
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)"
  };

export const Home = () => {
    return (
        <div className="bg-custom__med">
            <Parallax
                bgImage={bgImg1}
                bgImageAlt="the dog"
                strength={200}
            >
                <div style={{ height: '500px', width: '100vw', maxWidth: '100vw' }}>
                    <div style={insideStyles} className="pacifica">
                        <Row className="text-center">
                            <Col>
                            <h1 className="display-1 home-header__text">Nox Falls</h1>
                            </Col>
                        </Row>
                        <Row className="text-center">
                            <Col>
                            <h2 className="home-subheader__text">Award Winning Burlesque Performer, Educator, and Cosplay Entertainer</h2>
                            </Col>
                        </Row>
                        <Row className="text-center">
                            <Col>
                                <Link to="/shop">
                                    <Button href="/shop" className="bg-custom__dark btn btn-dark btn-border-none" size="lg">Explore Shop</Button>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Parallax>
            <Container className="rajdhani-semibold">
                <Row style={{minHeight: "200px", height: "auto"}} className="align-items-center">
                    <Col className="home-inquiries">
                        <h2 class="text-center">Reach Out for Booking Inquiries and Availability</h2>
                        <Row className="text-center">
                            <Col md={4} lg={5} className="home-inquiries">
                                <Link to="/contact">
                                    <Button href="/contact" className="bg-custom__dark btn btn-dark btn-border-none" size="lg">Perform</Button>
                                </Link>
                                
                            </Col>
                            <Col md className="home-inquiries">
                                <Link to="/contact">
                                    <Button href="/contact" className="bg-custom__dark btn btn-dark btn-border-none" size="lg">Appearances</Button>
                                </Link>
                                
                            </Col>
                            <Col md={4} lg={5} className="home-inquiries">
                                <Link to="/education">
                                    <Button href="/education" className="bg-custom__dark btn btn-dark btn-border-none" size="lg">Learn</Button>
                                </Link>
                                
                            </Col>   
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Parallax
                bgImage={bgImg2}
                bgImageAlt="the dog"
                strength={200}
            >
                <div style={{ height: '500px', width: '100vw', maxWidth: '100vw' }}>
                    <div style={insideStyles} className="pacifica">
                        <Row className="text-center">
                            <Col>
                            <h2 className="home-subheader__text">They'll do anything for you,<br></br> in the dark...</h2>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Parallax>
            <Container className="rajdhani-semibold">
                <Row>
                    <Col className="home-inquiries">
                        <h2 class="text-center">"An absolute must see..."</h2>
                        <Row className="text-center">
                            <Col sm className="home-inquiries">
                                <p>Burlesque Hall of Fame Queen Competitor & International Headliner</p>
                            </Col>
                            <Col sm className="home-inquiries">
                                <p>
                                    #28 on The Burlesque Top 50 2021
                                    <br></br>
                                    #79 on the Burlesque Top 100 2019
                                </p>
                            </Col>
                            <Col sm className="home-inquiries">
                                <p>2018 Oregon Burlesque Festival Queen of Roses</p>
                            </Col>   
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Parallax
                bgImage={bgImg3}
                bgImageAlt="the dog"
                strength={200}
            >
                <div style={{ height: '500px', width: '100vw', maxWidth: '100vw' }}>
                    <div style={insideStyles} className="pacifica">
                        <Row className="text-center">
                            <Col md={12}>
                            <h2 className="home-subheader__text">An Unapologetic Black & Queer Experience</h2>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Parallax>
        </div>
    )
}