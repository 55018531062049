/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const Footer = () => {
    return(
        <footer className="rajdhani bg-custom__med">
            <Container>
                <br></br>
            <Row className="justify-content-center">
                <Col md={6}>
                <h5>Nox Falls</h5>
                <p>
                    Thank you for visiting my site and collected works!
                    <br></br> 
                    Want more Nox Falls? - Take a look at my other projects and follow me on all the socials! 
                </p>
                </Col>
                <Col md={3}>
                <h5>Other Projects</h5>
                <ul class="footer-list footer-content">
                    <li><a target="_blank" class="deep-purple-text text-lighten-5" href="https://instagram.com/doomwitchcrafting">Doomwitch Crafting</a></li>
                    <li><a target="_blank" class="deep-purple-text text-lighten-5" href="https://instagram.com/darkdiamondsburlesque">Dark Diamonds Burlesque</a></li>
                    <li><a target="_blank" class="deep-purple-text text-lighten-5" href="https://instagram.com/darkvioletproductions">Dark Violet Productions</a></li>
                    <li><a  target="_blank"class="deep-purple-text text-lighten-5" href="https://glittercrash.com">Glitter Crash</a></li>
                </ul>
                </Col>
                <Col md={3}>
                <h5>Social Media</h5>
                <ul class="footer-list footer-content">
                    <li><a target="_blank" href="https://instagram.com/thenaughtybody">Instagram</a></li>
                    <li><a target="_blank" href="https://www.tiktok.com/@noxfvckingfalls">TikTok</a></li>
                </ul>
                </Col>
            </Row>
            </Container>
            <div>
            <Container class="container">
            &copy; 2022 Nox Falls, All Rights Reserved. This website was made by <a target="_blank"class="deep-purple-text text-lighten-5" href="https://glittercrash.com">Glitter Crash, LLC</a>
            </Container>
            </div>
        </footer>
    )
}