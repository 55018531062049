import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ContactUs } from "./components/ContactUs";

export const Contact = () => {
    return (
        <div className="bg-gradient_gallery">
            <br></br>
            <Container>
                <Row>
                    <h2 className="pacifica subheader__text">Contact Me</h2>
                    <Col>
                        <ContactUs />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}