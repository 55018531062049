import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Row, Col, Form, Button } from 'react-bootstrap';

export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_l9uezw2', 'template_oyqbap8', form.current, 'dGD1os-Ir0hxU_zQz')
      .then((result) => {
          console.log(result.text);
          alert("Message sent!");
          document.getElementById('contact-form').reset();
      }, (error) => {
          console.log(error.text);
          alert("Something went wrong - please try again later!");
      });
  };

  return (
    <Form ref={form} onSubmit={sendEmail} id="contact-form">
        <Form.Group className='mb-3'>
        <Row className='shop-card_margin'>
            <Col sm md={4}>
                <Form.Label className='rajdhani-semibold font-size_large'>Name</Form.Label>
                <Form.Control type="text" name="user_name" />
            </Col>
        </Row>
        <Row className='shop-card_margin'>
            <Col sm md={4}>
                <Form.Label className='rajdhani-semibold font-size_large'>Email</Form.Label>
                <Form.Control type="email" name="user_email" />
                <Form.Text muted>We'll never share your email with anyone else.</Form.Text>
            </Col>
        </Row>
        <Row className='shop-card_margin'>
            <Col sm md={4}>
                <Form.Label className='rajdhani-semibold font-size_large'>Subject</Form.Label>
                <Form.Control type="text" name="user_subject" />
            </Col>
        </Row>
        <Row className='shop-card_margin'>
            <Col sm md={6}>
                <Form.Label className='rajdhani-semibold font-size_large'>Message</Form.Label>
                <Form.Control 
                    name="message" 
                    as="textarea" 
                    rows={5}
                />
            </Col>
        </Row>

        <Button className="bg-custom__dark btn btn-dark btn-border-none" type="submit" value="Send">Send</Button>
      </Form.Group>
    </Form>
  );
};