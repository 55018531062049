import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const AboutContent = () => {
    return (
           <Row>
                <Col>
                    <p className="rajdhani">I entertain with slow burn striptease numbers that focus on kink, poetry, and seduction combined with attention to detail, musicality, and award-winning costuming.</p>
                    <p className="rajdhani">My numbers have been featured and competed across the country - winning titles such as "Judges' Choice", "Best Overall", and Queen.</p>
                </Col>
           </Row>
        
    )
}