import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Redbubble } from "./components/Redbubble";

export const Shop = () => {
    return (
        <div className="bg-gradient_gallery">
            <br></br>
            <Container>
                <Row>
                    <h2 className="pacifica subheader__text">Welcome to My Shop</h2>
                    <Col>
                    <h4 className="rajdhani-semibold">Check out some of my favorite photography sessions and designs below!</h4>
                    <h4 className="rajdhani-semibold">Looking for something not featured below? Feel free to <Link to="/contact"><a class="deep-purple-text text-lighten-5 underline-link" href="/contact">send me a message</a></Link> and let's chat!</h4>
                    <br></br>
                    </Col>
                    
                        <Redbubble />
                    
                    
                </Row>
            </Container>
        </div>
        
    )
}