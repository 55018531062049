import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";


export const AppHeader = () => {
    return(
        <Navbar variant="dark" expand="lg" className="pacifica bg-custom__med">
            <Container>
                <Navbar.Brand href="/">Nox Falls</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="justify-content-end ms-auto">
                            <Nav.Item>
                                <Link to="/">
                                    <Nav.Link href="/">Home</Nav.Link>
                                </Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="/about">
                                    <Nav.Link href="/about">About</Nav.Link>
                                </Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="/shop">
                                    <Nav.Link href="/shop">Shop</Nav.Link>
                                </Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="/gallery">
                                    <Nav.Link href="/gallery">Gallery</Nav.Link>
                                </Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="/calendar">
                                    <Nav.Link href="/calendar">Calendar</Nav.Link>
                                </Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="/education">
                                    <Nav.Link href="/education">Education</Nav.Link>
                                </Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="/contact">
                                    <Nav.Link href="/contact">Contact</Nav.Link>
                                </Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}