import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import ListGroup from "react-bootstrap/ListGroup";
import { Button } from "react-bootstrap";
import novImage from "../../assets/img/calendar/morbid-curiositease_11-2022.jpeg";

export const Calendar = () => {
    return (
        <div className="bg-gradient_gallery">
            <br></br>
            <Container>
                <Row>
                    <h2 className="pacifica subheader__text">Upcoming Shows & Appearances</h2>
                    
                    <Col sm lg={4}>
                        <Image 
                        className="img-height_75vh"
                            src={novImage}
                            fluid={true}
                            rounded={true}
                        ></Image>
                    </Col>
                    <Col sm lg={8}>
                        <h4 className="rajdhani-semibold">Morbid Curiositease</h4>
                        <ListGroup variant="flush">
                            <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                                Friday, November 11, 2022
                                <br></br>
                                9:00pm-11:00pm
                            </ListGroup.Item>
                            <ListGroup.Item className="background-color_transparent rajdhani btn-border-none">
                                Seattle's own sorceress of the lascivious arts, La Petite Mort, and her partner in grime, the insidious Mr. Ink, bring an all new raucous escapade to West Seattle. Morbid Curiositease explores the vivacious heartbeat of cult culture with a cabal of eccentric performance artists dragging the audience down into the sexy shadows of sinister cabaret.  You're invited to join La Petite Mort and her Morbid coterie on an anomalous adventure of erotic proportions. There will be skin, there will screams, there will be a point of no return. 21+
                            </ListGroup.Item>
                            <ListGroup.Item 
                                className="background-color_transparent rajdhani btn-border-none" 
                                action>
                                    <Button className="bg-custom__dark btn btn-dark btn-border-none" variant="primary" href="https://anthology.ticketleap.com/morbid-curiositease1/" target="_blank">Purchase Tickets Here!</Button>
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <br></br>
                </Row>
                <br></br>
            </Container>
        </div>
    )
}